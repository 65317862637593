import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"];

  initialize() {
    this.stickyItem = null;
    this.lastScrollY = window.scrollY;
    this.navHeight = this.getNavHeight();
  }

  connect() {
    window.addEventListener("scroll", this.scrollHandler.bind(this));
    window.addEventListener("resize", this.resizeHandler.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.scrollHandler.bind(this));
    window.addEventListener("resize", this.resizeHandler.bind(this));
  }

  scrollHandler() {
    this.checkStickyItems();
  }

  resizeHandler() {
    this.navHeight = this.getNavHeight();
    this.checkStickyItems();
  }

  getNavHeight() {
    const navbar = document.getElementById("navbar");
    return navbar ? navbar.offsetHeight : 0;
  }

  checkStickyItems() {
    const currentScrollY = window.scrollY;

    this.itemTargets.forEach((item, index) => {
      const rect = item.getBoundingClientRect();
      const nextItem = this.itemTargets[index + 1];
      const prevItem = this.itemTargets[index - 1];

      if (
        rect.top <= this.navHeight &&
        (!nextItem || nextItem.getBoundingClientRect().top > this.navHeight)
      ) {
        this.makeSticky(item, this.navHeight);
      }

      if (prevItem && rect.top > this.navHeight) {
        this.unstick(item);
      }
    });

    this.lastScrollY = currentScrollY;
  }

  makeSticky(item, navHeight) {
    if (this.stickyItem !== item) {
      if (this.stickyItem) {
        this.stickyItem.style.transform = "";
        this.stickyItem.classList.remove("sticky-top");
      }

      item.classList.add("sticky-top");
      item.style.top = `${navHeight - 1}px`;
      this.stickyItem = item;
    }

    const nextItem = this.itemTargets[this.itemTargets.indexOf(item) + 1];
    if (nextItem) {
      const nextItemRect = nextItem.getBoundingClientRect();
      if (nextItemRect.top <= navHeight + item.offsetHeight) {
        item.style.transform = `translateY(${
          nextItemRect.top - (navHeight + item.offsetHeight)
        }px)`;
      } else {
        item.style.transform = "";
      }
    }
  }

  unstick(item) {
    item.classList.remove("sticky-top");
    item.style.top = "";
    item.style.transform = "";
  }
}
