import consumer from "./consumer";
import { TableStacking } from "packs/table_management/table_stacking";

document.addEventListener("turbolinks:load", function () {
  // Get the current path from the URL
  const currentPath = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);
  const paramDate = urlParams.get("date");

  // Check if the current path matches /restaurant/reservations
  if (currentPath === "/restaurant/floor_plans/on_date") {
    consumer.subscriptions.create("OnDateChannel", {
      received(data) {
        if (paramDate && paramDate !== data.reservation_date) {
          console.log("returning...");
          return; // Exit if the dates don't match
        }

        this.updateReservations(data.reservation_date);
        this.tableStacking(data.floor_plan_id, data.reservation_date);
      },

      tableStacking(floorId, date) {
        $.ajax({
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader(
              "X-CSRF-Token",
              $('meta[name="csrf-token"]').attr("content")
            );
          },
          type: "POST",
          url: `/restaurant/floor_plans/${floorId}/table_stacking`,
          data: { date: date },
          success: function (data) {
            let tableStacker = new TableStacking(JSON.parse(data.table_stacks));
            tableStacker.stack();
          },
          error: function (data) {
            console.log(data);
          },
        });
      },

      updateReservations(date) {
        fetch(
          "/restaurant/floor_plans/reservations/on_date_reservations?" +
            new URLSearchParams({ date: date }),
          {
            method: "PUT",
            headers: {
              "X-CSRF-Token": this.getCSRFToken(),
              "Content-Type": "application/json",
              Accept: "text/html",
            },
          }
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.text();
          })
          .then((html) => {
            document.getElementById("reservations").innerHTML = html;
            setTimeout(() => {
              this.setupToolTips();
            }, 100);
          })
          .catch((error) => {
            console.error(
              "There has been a problem with your fetch operation:",
              error
            );
          });
      },

      getCSRFToken() {
        return document
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content");
      },

      setupToolTips() {
        var tooltipTriggerList = [].slice.call(
          document.querySelectorAll('[data-bs-toggle="popover"]')
        );
        tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Popover(tooltipTriggerEl);
        });
      },
    });
  }
});
